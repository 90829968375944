import { logEvent } from 'firebase/analytics';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import PinInput from 'react-pin-input';
import Toastify from 'toastify-js';
import { analytics } from './firebase';

window.onerror = function (message) {
  logEvent(analytics, 'js_error', { message });
};

const APP_NAME = app.config().app.app_name;
const APP_PAGE_TITLE = app.config().app.app_page_title;
const APP_PAGE_FOOTER_TEXT = app.config().app.app_page_footer_text;
const FIREBASE_PROJECT_ID =
  app.config().x_firebase_project.id || app.config().x_firebase_project_2.id;
const LOCAL_HOST = app.config().telegram_bot.local_host;

// Not working...
// const KEYPAD_API_FUNCTION_REGION =
//   app.config().function_region.check_passcode;
const KEYPAD_API_FUNCTION_REGION = 'asia-east1';

const PING_ENDPOINT = LOCAL_HOST
  ? `https://${LOCAL_HOST}/${FIREBASE_PROJECT_ID}/${KEYPAD_API_FUNCTION_REGION}/keypadAPI?operation=ping`
  : `https://${KEYPAD_API_FUNCTION_REGION}-${FIREBASE_PROJECT_ID}.cloudfunctions.net/keypadAPI?operation=ping`;
const CHECK_PASSCODE_ENDPOINT = LOCAL_HOST
  ? `https://${LOCAL_HOST}/${FIREBASE_PROJECT_ID}/${KEYPAD_API_FUNCTION_REGION}/keypadAPI?operation=check_passcode`
  : `https://${KEYPAD_API_FUNCTION_REGION}-${FIREBASE_PROJECT_ID}.cloudfunctions.net/keypadAPI?operation=check_passcode`;
const OPEN_DOOR_ENDPOINT = LOCAL_HOST
  ? `https://${LOCAL_HOST}/${FIREBASE_PROJECT_ID}/${KEYPAD_API_FUNCTION_REGION}/keypadAPI?operation=open_door`
  : `https://${KEYPAD_API_FUNCTION_REGION}-${FIREBASE_PROJECT_ID}.cloudfunctions.net/keypadAPI?operation=open_door`;

const ERROR_CONTACT = app.config().app.error_contact;

const INITIAL_PASSCODE = (() => {
  const pathnameMatch = window.location.pathname.match(/[0-9]+$/);

  if (pathnameMatch) {
    return pathnameMatch[0] || '';
  }

  return '';
})();

if (APP_PAGE_TITLE || APP_NAME) {
  document.title = APP_PAGE_TITLE || APP_NAME || '';
}

function App(): JSX.Element {
  useEffect(() => {
    try {
      fetch(PING_ENDPOINT);
    } catch (e) {
      console.error(e);
    }
  }, []);
  const pinInputEle = useRef<any>(null);

  const [passcode, setPasscode] = useState(INITIAL_PASSCODE);
  const passcodeRef = useRef(passcode);
  passcodeRef.current = passcode;

  const [checkingPasscode, setCheckingPasscode] = useState(false);
  const [passcodeChecked, setPasscodeChecked] = useState(false);
  const [passcodeInvalid, setPasscodeInvalid] = useState(false);
  const [passcodeCheckData, setPasscodeCheckData] = useState<any>(null);

  const checkPasscode = useCallback(async () => {
    (document.activeElement as HTMLElement)?.blur();
    const startTime = new Date().getTime();
    setCheckingPasscode(true);

    let isInvalid = false;
    try {
      logEvent(analytics, 'check_passcode', {});

      const resp = await fetch(
        `${CHECK_PASSCODE_ENDPOINT}&code=${passcodeRef.current}`,
        { method: 'POST' },
      );
      const data = await resp.json();

      if (data.valid) {
        setPasscodeInvalid(false);
        logEvent(analytics, 'check_passcode_success', {
          time_elapsed_ms: new Date().getTime() - startTime,
        });
      } else {
        setPasscodeInvalid(true);
        logEvent(analytics, 'check_passcode_fail', {
          reason: data.reason,
          reason_text: data.reasonText,
          time_elapsed_ms: new Date().getTime() - startTime,
        });
        isInvalid = true;
      }
      setPasscodeCheckData(data);
      setPasscodeChecked(true);
    } catch (e) {
      logEvent(analytics, 'check_passcode_error', {
        message: e.message,
        time_elapsed_ms: new Date().getTime() - startTime,
      });

      Toastify({
        text: `發生錯誤，請再試一次。${
          ERROR_CONTACT ? `若問題持續發生，請聯絡 ${ERROR_CONTACT}。` : ''
        }`,
        duration: 3000,
        // close: true,
        gravity: 'top', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          color: '#721c24',
          background: '#f8d7da',
          border: '1px solid #f5c6cb',
          'border-radius': '0.25rem',
          'font-weight': '500',
        },
        // onClick: function () {
        //   alert(`錯誤資訊：${JSON.stringify(data)}`);
        // }, // Callback after click
      }).showToast();
    } finally {
      setCheckingPasscode(false);
      if (isInvalid) {
        const lastPinInput =
          pinInputsRef.current &&
          pinInputsRef.current[pinInputsRef.current.length - 1];
        if (lastPinInput) {
          lastPinInput.focus();
          setTimeout(() => {
            lastPinInput.setSelectionRange(0, 1);
            lastPinInput.select();
          }, 80);
        }
      }
    }
  }, []);

  useEffect(() => {
    setPasscodeChecked(false);
  }, [passcode]);

  // Check the initial passcode
  useEffect(() => {
    if (passcodeRef.current && passcodeRef.current.length >= 0) {
      checkPasscode();
    }
  }, [checkPasscode]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     pinInputEle?.current?.focus();
  //   }, 100);
  // }, []);

  // For UX improvements
  const pinInputsRef = useRef<any>([]);
  const hasKeydownOnPinInput = useRef(false);
  const isPinInputFirstFocus = useRef(true);
  useEffect(() => {
    // Only do this if we have no pre-filled passcode
    if (passcode) return;

    setTimeout(() => {
      const pinInputs = document.querySelectorAll('.pincode-input-text');
      pinInputsRef.current = pinInputs;

      pinInputs.forEach((inputElement, i) => {
        // For mobile device UX: focus first the field when focusing on any other fields
        // on the first time the input is focused
        inputElement.addEventListener('focus', (event) => {
          if (!isPinInputFirstFocus.current) return;
          if (passcodeRef.current) return;
          if (hasKeydownOnPinInput.current) return;

          isPinInputFirstFocus.current = false;
          pinInputEle?.current?.focus();
        });

        inputElement.addEventListener('keydown', (event) => {
          hasKeydownOnPinInput.current = true;
          switch ((event as any).key || (event as any).code) {
            case 'ArrowLeft': {
              const prevInput = pinInputs[i - 1];
              if (prevInput) {
                (prevInput as any).focus();
                setTimeout(() => {
                  (prevInput as any).setSelectionRange(0, 1);
                  (prevInput as any).select();
                }, 80);
              }
              break;
            }
            case 'ArrowRight': {
              const nextInput = pinInputs[i + 1];
              if (nextInput) {
                (nextInput as any).focus();
                setTimeout(() => {
                  (nextInput as any).setSelectionRange(0, 1);
                  (nextInput as any).select();
                }, 80);
              }
              break;
            }
          }
        });
      });
    }, 100);
    // Do not need to run again if passcode changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleText = (() => {
    if (checkingPasscode) {
      return '驗證中⋯⋯';
    }

    if (passcodeChecked && !passcodeInvalid) {
      return '驗證成功';
    }

    if (passcodeInvalid) {
      return '密碼無效，請重新輸入';
    }

    return '請輸入密碼';
  })();

  return (
    <div className="form-passcode">
      <h1 className="h3 mb-3 fw-bold">{titleText}</h1>
      <div className="passcode-input-container mb-3">
        <PinInput
          ref={(n) => {
            pinInputEle.current = n;
          }}
          length={6}
          initialValue={passcode}
          focus={!passcode}
          onChange={(value, index) => {
            passcodeRef.current = value;
            setPasscodeChecked(false);
            setPasscodeInvalid(false);
            setPasscode(value);
          }}
          onComplete={(value, index) => {
            passcodeRef.current = value;
            setPasscodeChecked(false);
            setPasscodeInvalid(false);
            setPasscode(value);
            checkPasscode();
          }}
          disabled={checkingPasscode}
          type="numeric"
          inputMode="number"
          style={{ padding: '8px' }}
          inputStyle={{
            padding: '0.375rem 0.75rem',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            lineHeight: '1.5',
            color: passcodeChecked
              ? passcodeInvalid
                ? '#dc3545'
                : '#28a745'
              : '#212529',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ced4da',
            borderColor: passcodeInvalid
              ? '#dc3545'
              : passcodeChecked
              ? '#28a745'
              : '#ced4da',
            appearance: 'none',
            borderRadius: '0.375rem',
            transition:
              'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
          }}
          inputFocusStyle={{
            color: '#212529',
            backgroundColor: '#fff',
            borderColor: '#86b7fe',
            outline: 0,
            boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
        {checkingPasscode && (
          <div className="passcode-input-loading-container">
            <div className="spinner-border text-secondary" role="status" />
          </div>
        )}
      </div>
      {!!(passcodeChecked && passcodeCheckData?.message) && (
        <p style={{ marginTop: '-10px', padding: '0 16px' }}>
          留言：{passcodeCheckData?.message}
        </p>
      )}
      <div className="open-door-buttons-container">
        {(() => {
          if (
            !passcodeChecked ||
            (passcodeCheckData?.doors?.length || 0) <= 0
          ) {
            return (
              <button
                className="btn btn-lg btn-secondary"
                style={{ opacity: 0.4 }}
                disabled
              >
                開門
              </button>
            );
          }

          return passcodeCheckData?.doors?.map((door: any, i: any) => {
            return (
              <OpenDoorButton
                key={door.id}
                passcode={passcodeRef.current}
                doorId={door.id}
                doorName={door.name}
                primary={i === 0}
              />
            );
          });
        })()}
      </div>
      <p className="mt-5 mb-3 text-muted">{APP_PAGE_FOOTER_TEXT}</p>
    </div>
  );
}

function OpenDoorButton({
  doorName,
  passcode,
  doorId,
  primary,
}: {
  passcode: string;
  doorId: string;
  doorName?: string;
  primary?: boolean;
}) {
  const [loading, setLoading] = useState(false);

  return (
    <button
      className={`btn btn-lg mb-2${
        primary ? ' btn-primary' : ' btn-secondary'
      }`}
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        const startTime = new Date().getTime();

        try {
          logEvent(analytics, 'open_door', { door_id: doorId });

          const timestamp = Math.ceil(new Date().getTime() / 1000);
          const resp = await fetch(
            `${OPEN_DOOR_ENDPOINT}&code=${passcode}&doorId=${doorId}&timestamp=${timestamp}`,
            { method: 'POST' },
          );
          const data = await resp.json();

          if (data?.success) {
            logEvent(analytics, 'open_door_success', {
              door_id: doorId,
              time_elapsed_ms: new Date().getTime() - startTime,
            });
            Toastify({
              text: `已解鎖 ${doorName || doorId}`,
              duration: 3000,
              gravity: 'top', // `top` or `bottom`
              position: 'center', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                color: '#155724',
                background: '#d4edda',
                border: '1px solid #c3e6cb',
                'border-radius': '0.25rem',
                'font-weight': '500',
              },
            }).showToast();
          } else {
            logEvent(analytics, 'open_door_error', {
              door_id: doorId,
              reason: data.reason,
              reason_text: data.reasonText,
              time_elapsed_ms: new Date().getTime() - startTime,
            });

            Toastify({
              text: `發生錯誤，請再試一次。${
                ERROR_CONTACT ? `若問題持續發生，請聯絡 ${ERROR_CONTACT}。` : ''
              }`,
              duration: 3000,
              // close: true,
              gravity: 'top', // `top` or `bottom`
              position: 'center', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                color: '#721c24',
                background: '#f8d7da',
                border: '1px solid #f5c6cb',
                'border-radius': '0.25rem',
                'font-weight': '500',
              },
              // onClick: function () {
              //   alert(`錯誤資訊：${JSON.stringify(data)}`);
              // }, // Callback after click
            }).showToast();
          }
        } catch (e) {
          logEvent(analytics, 'open_door_error', {
            door_id: doorId,
            message: e.message,
            time_elapsed_ms: new Date().getTime() - startTime,
          });

          Toastify({
            text: `發生錯誤，請再試一次。${
              ERROR_CONTACT ? `若問題持續發生，請聯絡 ${ERROR_CONTACT}。` : ''
            }`,
            duration: 3000,
            // close: true,
            gravity: 'top', // `top` or `bottom`
            position: 'center', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              color: '#721c24',
              background: '#f8d7da',
              border: '1px solid #f5c6cb',
              'border-radius': '0.25rem',
              'font-weight': '500',
            },
            // onClick: function () {
            //   alert(`錯誤資訊：${JSON.stringify(data)}`);
            // }, // Callback after click
          }).showToast();
        } finally {
          setLoading(false);
        }
      }}
    >
      {(() => {
        if (loading) {
          return (
            <>
              <div className="spinner-grow text-light" />
              解鎖中⋯⋯
            </>
          );
        }
        return `開啟 ${doorName || doorId}`;
      })()}
    </button>
  );
}

export default App;
